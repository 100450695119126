.Console {
  width: 100%;

  pre {
    background: black;
    color: white;
    padding: 25px;
    font-size: 12px;
    line-height: 15px;
    height: 500px;
    overflow-y: scroll;
    opacity: 0.35;
    text-align: left;
    white-space: pre-wrap;
    // overflow-x: unset;
    // overflow-wrap: break-word;
    // word-wrap: break-word;

    &:after {
      content: '[click here]';
      // width: 8px;
      // height: 16px;
      // margin-bottom: -4px;
      // background: white;
      opacity: 1;
      display: inline-block;
      animation: blink 0.75s linear infinite alternate;
    }
  }

  &.active {
    pre {
      opacity: 1;

      &:after {
        content: '';
        width: 8px;
        height: 16px;
        margin-bottom: -4px;
        background: white;
        opacity: 0;
        display: inline-block;
        animation: blink 0.75s linear infinite alternate;
      }
    }
  }
}

@keyframes blink {
  50% {
    opacity: 0;
  }
  80% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}
