.App {
  text-align: center;
  background-color: #282c34;
  min-height: 100vh;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: 'Syne Mono', monospace;

  header, button {
    margin: 25px auto;
  }
}

.App-link {
  color: #61dafb;
}

.App-alerts {
  position: fixed;
  top: 25px;
  right: 25px;

  .toast-header {
  }

  .badge {
    // margin: 0 10px;
    // float: right;
    // margin-left: 25px;
    position: absolute;
    right: 10px;
  }
}

.App-logo {
  max-width: 50px;
  pointer-events: none;
  animation: pulse infinite 10s linear;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: pulse infinite 10s linear;
  }
}

.App-name {
  font-family: 'Syne Mono', monospace;
}

.App-link {
  color: #61dafb;
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1)
  }
}
